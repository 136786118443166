<template>
  <div>
    <b-container class="mb-4">
      <h2 class="page-title">
        Affilate Profile
      </h2>
      <tabs
        :tabItems="TAB_ITEMS"
        defaultTab="general-details">
        <template slot="tab-content">
          <component
            :is="activeTabComponent"/>
          <router-view />
        </template>
      </tabs>
    </b-container>
  </div>
</template>

<script>
import { AffiliateProfileTabs } from '@/services/tabs';
import Tabs from '@/views/components/Tabs.vue';
import GeneralDetails from './view-partials/GeneralDetails.vue';
import ContactDetails from './view-partials/ContactDetails.vue';
import Roles from './view-partials/Roles.vue';
import Addresses from './view-partials/Addresses.vue';

export default {
  components: {
    Tabs,
    GeneralDetails,
    ContactDetails,
    Addresses,
    Roles,
  },
  created() {
    this.TAB_ITEMS = AffiliateProfileTabs;
  },
  computed: {
    activeTabComponent() {
      return this.$route.query.section;
    },
  },
};
</script>
