<template>
  <div class="card">
    <div class="card-body general-details">
      <h4 class="font-weight-bold d-block mb-4">
        Roles in IFAC
      </h4>
      <!--  -->
      <ifac-loader class="p-5" v-if="busy"></ifac-loader>
      <div v-else>
        <div class="row my-4 text-center" v-if="!mx_hasPositions">
          <div class="col">
            <div class="mb-4">
              <i class="far fa-exclamation fa-4x text-primary"></i>
            </div>
            <p>No roles</p>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col mt-3">
            <p v-for="(position, key) in mx_sortedAffiliatePositions" :key="key">
              {{ mx_getPositionName(position) }}
              <span class="d-block alert alert-light p-2 border" v-if="position.notes">
                <IfacRoleNotes :text="position.notes"/>
              </span>
            </p>
          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Affiliates from '@/services/Api/Affiliates';
import AffiliatesPublic from '@/services/Api/Public/Affiliates';
import { IfacRoleNotes, IfacLoader, ifacPositionsMixin } from '@ifac/ui';

export default {
  components: {
    IfacLoader,
    IfacRoleNotes,
  },
  mixins: [ifacPositionsMixin],
  created() {
    this.fetchData();
  },
  data() {
    return {
      busy: false,
      currentPositions: [],
    };
  },
  computed: {
    ...mapState({
      authed: (state) => state.auth.isAuthenticated,
    }),
  },
  methods: {
    async fetchAffiliate() {
      const {
        data: { data },
      } = await Affiliates.show(this.$route.params.id);

      this.currentPositions = data.positions;
    },
    async fetchAffiliatePublic() {
      const {
        data: { data },
      } = await AffiliatesPublic.show(this.$route.params.id);

      this.currentPositions = data.positions;
    },
    async fetchData() {
      this.busy = true;
      if (this.authed) {
        await this.fetchAffiliate();
      } else {
        await this.fetchAffiliatePublic();
      }
      this.busy = false;
    },
  },
};
</script>
