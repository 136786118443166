<template>
   <span>
     <span v-if="hasValue && isPrivate"
           v-b-tooltip.hover
           title="This data is set by you as Private and is not accessible to other Affiliates"
     >
          <font-awesome-icon :icon="['fas', 'lock']" size="sm"/>
     </span>
   </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    affiliateId: String,
    contactInfo: Object,
  },
  computed: {
    ...mapGetters({
      authed: 'auth/isAuthenticated',
      user: 'auth/user',
    }),
    hasValue() {
      return !!this.contactInfo ?? null;
    },
    isOwner() {
      return this.authed && this.user.id === this.affiliate.id;
    },
    isPrivate() {
      return this.contactInfo.visibility === 'private' ?? null;
    },
  },
};
</script>
